<template>

  <div class="background" :class="[store.getTheme()]" >
    <div class="row"></div>
    <div class="row">
      <router-link :class="[store.getTheme()]" class="btn" :to="{ name: 'Feed' }" >
        <font-awesome-icon class="valign left" :icon="['fas', 'chevron-left']" size="lg" />
        <!-- <i class="material-icons left ">arrow_back</i> -->
        <span class="small-padding-left">Revenir à l'actualité</span>
      </router-link>
    </div>
    <div :class="[store.getTheme()]" class="card">
      <div class="card-content">
        <h4>Mahana Tahiti Live</h4>
        <iframe src="https://iframe.dacast.com/live/d1967ba6-ac43-aa98-f6a9-59242d8eb233/6b4350c3-923a-8e0a-cd24-5311298b41dc" width="100%" height="100%" frameborder="0" scrolling="no" allow="autoplay" allowfullscreen webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>
      </div>
<br><br>
    </div>
  </div>
</template>
<script>
import { store } from '../store'
export default {
  name: 'Live',
  data () {
    return {
      store: store
    }
  },
  mounted () {
    // const elems = document.querySelectorAll('.pushpin')
    // eslint-disable-next-line
    // M.Pushpin.init(elems, {offset: 50,top: 100})
    // var elems = document.querySelectorAll('.slider')
    // // eslint-disable-next-line
    // M.Slider.init(elems)
    const elems = document.querySelectorAll('.carousel')
    if (elems.length > 0) {
      // eslint-disable-next-line
      M.Carousel.init(elems, {fullWidth: true, numVisible:1, shift:0, padding:200, dist:1})
      // fullWidth: true, indicators: true
    }
  }
}
</script>
<style scoped>
  li {
    list-style-type: circle !important;
    margin-left: 40px;
  }

  p {
    padding-top:10px;
  }

  .card {
  color:white;
  background: linear-gradient(to bottom, rgba(27, 27, 27, 70%) 0%,rgba(27,27,27,1) 100%); /* W3C */
    margin: 0 0 0 0!important
}
.card-content {
  height: 600px;
  padding: 0;
}
</style>
